import Layout from 'common/components/Layout'
import Seo from 'components/seo'
import MessageVideo from 'containers/MessageVideo'
import RestaurantHero from 'containers/RestaurantHero'
import Results from 'containers/Results'
import Testimonials from 'containers/Testimonials'
import * as React from 'react'

const NRALandingPage = ({ location }) => {
  const contactUsFooterOverRide = `Still have questions?`
  const title = 'National Restaurant Association Show 2022'
  return (
    <Layout
      location={location.pathname}
      contactUsFooterOverride={contactUsFooterOverRide}
    >
      <Seo title={title} />
      <RestaurantHero />
      <Testimonials />
      <MessageVideo />
      <Results />
    </Layout>
  )
}

export default NRALandingPage
